import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import Paginate from "../components/paginate";
import { listEmployees } from "../actions/employeeActions";
import { Link } from "react-router-dom";
import { BsArrowDownRightSquareFill } from "react-icons/bs";

const BpjsTableView = ({ keyword, pageNumber, isActiveEmployee }) => {
 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 useEffect(() => {
  if (!employees) {
   dispatch(listEmployees(keyword, pageNumber));
  }
 }, [dispatch, keyword, pageNumber, employees]);

 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems;
   if (items.length) {
    sortableItems = [...items];
   } else {
    sortableItems = items;
   }

   if (sortConfig !== null) {
    if (items.length) {
     sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
     });
    }
   }
   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <table striped bordered hover responsive>
    <thead style={{ position: "sticky", top: 0 }}>
     <tr>
      <th>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name")}
       >
        NAME
       </button>
      </th>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("department")}
        className={getClassNamesFor("department")}
       >
        DEPARTMENT
       </button>
      </th>
      <th style={{ textAlign: "right" }}>
       <button
        type="button"
        onClick={() => requestSort("company")}
        className={getClassNamesFor("company")}
       >
        COMPANY
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("bpjsKetenagakerjaan")}
        className={getClassNamesFor("bpjsKetenagakerjaan")}
       >
        BPJS KETENAGAKERJAAN
       </button>
      </th>
      {
       <th style={{ textAlign: "left" }}>
        <button
         type="button"
         onClick={() => requestSort("bpjsKesehatan")}
         className={getClassNamesFor("bpjsKesehatan")}
        >
         BPJS KESEHATAN
        </button>
       </th>
      }

      <th style={{ width: "12em", textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("workingPeriod")}
        className={getClassNamesFor("workingPeriod")}
       >
        WORKING PERIOD
       </button>
      </th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin ||
     userInfo.isOutletLeader ||
     userInfo.isCompanyLeader ||
     userInfo.isSuperUser ? (
      items.map((item) =>
       isActiveEmployee ? (
        !item.dateResign ? (
         <tr key={item._id}>
          <td style={{ width: "18em" }}>
           <Link to={`/employee/${item._id}`}>
            <BsArrowDownRightSquareFill
             style={{ color: "#5b9bd5", fontSize: "80%" }}
            />{" "}
            {item.name}
           </Link>
          </td>

          <td>
           {item.department === "Finance, Accounting & Tax"
            ? "FAT"
            : item.department}
          </td>
          <td>
           <span
            style={{
             color: item.company === "Developer" ? "green" : "blue",
            }}
           >
            {item.company}
           </span>
          </td>

          <td>{item.bpjsKetenagakerjaan ? item.bpjsKetenagakerjaan : ""}</td>
          <td>{item.bpjsKesehatan ? item.bpjsKesehatan : ""}</td>
          <td style={{ textAlign: "left" }}>
           {item.workingPeriod ? item.workingPeriod : ""}
          </td>
         </tr>
        ) : null
       ) : item.dateResign ? (
        <tr key={item._id}>
         <td style={{ width: "18em" }}>
          <Link to={`/employee/${item._id}`}>
           <BsArrowDownRightSquareFill
            style={{ color: "#5b9bd5", fontSize: "80%" }}
           />{" "}
           {item.name}
          </Link>
         </td>
         <td>
          {item.department === "Finance, Accounting & Tax"
           ? "FAT"
           : item.department}
         </td>
         <td>
          <span
           style={{
            color: item.company === "Developer" ? "green" : "blue",
           }}
          >
           {item.company}
          </span>
         </td>

         <td>{item.bpjsKetenagakerjaan ? item.bpjsKetenagakerjaan : ""}</td>
         <td>{item.bpjsKesehatan ? item.bpjsKesehatan : ""}</td>
         <td style={{ textAlign: "left" }}>
          {item.workingPeriod ? item.workingPeriod : ""}
         </td>
        </tr>
       ) : null
      )
     ) : (
      <tr key={items._id}>
       <td>
        <Link to={`/employee/${items._id}`}>
         <BsArrowDownRightSquareFill
          style={{ color: "#5b9bd5", fontSize: "80%" }}
         />{" "}
         {items.name}
        </Link>
       </td>
       <td>
        {items.department === "Finance, Accounting & Tax"
         ? "FAT"
         : items.department}
       </td>
       <td>
        <span
         style={{
          color: items.company === "Developer" ? "green" : "blue",
         }}
        >
         {items.company}
        </span>
       </td>

       <td>{items.bpjsKetenagakerjaan ? items.bpjsKetenagakerjaan : ""}</td>
       <td>{items.bpjsKesehatan ? items.bpjsKesehatan : ""}</td>
       <td style={{ textAlign: "left" }}>
        {items.workingPeriod ? items.workingPeriod : ""}
       </td>
      </tr>
     )}
    </tbody>
   </table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
   <Paginate pages={pages} page={page} isAdmin={true} />
  </>
 );
};

export default BpjsTableView;
