import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getWorkDuration, capitalizeEachWord } from "../utils/utils";
import { WhatsAppLink } from "./whatsapp";
import { EmailLink } from "./email";
import verifiedIcon from "../Assets/icon/verified.png";
import IconStarRating from "./iconStarRating";

const duration = require("dayjs/plugin/duration");
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Employee = ({ stuentDetails: employee, outlet }) => {
 // Determine the class name based on the outlet
 const cardClassName = `my-3 p-1 rounded ${
  (employee.outlet === "SETIABUDI" && "employee-card-setiabudi") ||
  (employee.outlet === "SUDIRMAN" && "employee-card-sudirman") ||
  (employee.outlet === "SRIWIJAYA" && "employee-card-sriwijaya") ||
  (employee.outlet === "MANAGEMENT" && "employee-card-sriwijaya") ||
  (employee.outlet === "KHALID MEDAN" && "employee-card-sriwijaya") ||
  (employee.outlet === "KHALID JAKARTA" && "employee-card-mahakam") ||
  (employee.outlet === "MAHAKAM" && "employee-card-mahakam") ||
  (employee.outlet !== "SETIABUDI" &&
   employee.outlet !== "SUDIRMAN" &&
   employee.outlet !== "SRIWIJAYA" &&
   employee.outlet !== "MANAGEMENT" &&
   employee.outlet !== "KHALID MEDAN" &&
   employee.outlet !== "KHALID JAKARTA" &&
   employee.outlet !== "MAHAKAM" &&
   "employee-card-mahakam")
 }`;

 return (
  <Card className={cardClassName} style={{ textAlign: "center" }}>
   <Card.Body>
    <Link to={`/employee/${employee._id}`} style={{ textDecoration: "none" }}>
     <Card.Title as="div">
      <Row>
       <Col>
        <img
         src={
          employee && employee.profilePicture
           ? `../${employee.profilePicture}`
           : employee &&
             !employee.profilePicture &&
             `../uploads/images/profilePictures/blank-profile-picture.png`
         }
         alt="Profile"
         style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
         }}
        />
       </Col>
      </Row>
     </Card.Title>

     <Row>
      <Col>
       <span style={{ color: "white", fontWeight: "500" }}>
        {employee.name}
       </span>
       {typeof employee.status === "string" &&
        employee.status.toLowerCase().includes("tetap") && (
         <img
          src={verifiedIcon}
          style={{ width: "20px", height: "20px" }}
          alt="Verified"
         />
        )}
      </Col>
     </Row>
    </Link>
    <Row className="justify-content-md-center">
     <Col md="auto">
      {employee.phone && <WhatsAppLink phone={employee.phone} />}
     </Col>
     <Col md="auto">
      {employee.email && <EmailLink email={employee.email} color="white" />}
     </Col>
    </Row>
    <Row className="justify-content-md-center">
     <Col md="auto">
      <IconStarRating employee={employee} />
     </Col>
    </Row>
    <Link
     to={`/employee/${employee._id}`}
     style={{ textDecoration: "none" }}
     className="link-black"
    >
     <br />
     {!employee.phone && !employee.email && (
      <Row>
       <Col>
        <br />
        <br />
       </Col>
      </Row>
     )}
     <Row>
      <Col>
       <strong>{capitalizeEachWord(employee.outlet)}</strong>
      </Col>
     </Row>
     <Row className="justify-content-md-center">
      <Col md="auto">
       {employee.position &&
       typeof employee.position === "string" &&
       employee.position.length < 4 ? (
        <strong>{employee.position}</strong>
       ) : (
        <strong>{`${capitalizeEachWord(employee.position)}`}</strong>
       )}
      </Col>
     </Row>
     <Row className="justify-content-md-center">
      <Col md="auto">{employee.employeeID} </Col>
      <Col md="auto">
       {
        <i
         class={`${
          employee.sex === "Men"
           ? "fas fa-mars male-icon"
           : "fas fa-venus female-icon"
         }`}
         style={{
          color: `${employee.sex === "Men" ? "green" : "pink"}`,
          fontSize: "1.25em",
         }}
        />
       }
      </Col>
     </Row>

     {employee.dateIn && !employee.dateResign && (
      <Col md="auto">
       {`${dayjs(employee.dateIn)?.format("DD MMM YYYY")} - Present `}
       {
        <i
         class="fas fa-user"
         style={{ color: "green", paddingRight: "2px" }}
        ></i>
       }
      </Col>
     )}
     {employee.dateIn && employee.dateResign && (
      <Col md="auto">
       {`${dayjs(employee.dateIn)?.format("DD MMM YYYY")} - ${dayjs(
        employee.dateResign
       )?.format("DD MMM YYYY")} `}
       {
        <i
         class="fas fa-sign-out-alt"
         style={{ color: "red", paddingRight: "2px" }}
        ></i>
       }
      </Col>
     )}

     <Row>
      <Col>
       {employee.dateIn &&
        getWorkDuration(employee.dateIn, employee.dateResign)}
      </Col>
     </Row>
    </Link>
   </Card.Body>
  </Card>
 );
};

export default Employee;
