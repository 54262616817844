import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getWorkDuration, capitalizeEachWord } from "../utils/utils";

const duration = require("dayjs/plugin/duration");
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Outlet = ({ outletDetails: outlet }) => {
 // Determine the class name based on the outlet
 let cardClass = "";
 switch (outlet) {
  case "SETIABUDI":
   cardClass = "employee-card-setiabudi";
   break;
  case "SUDIRMAN":
   cardClass = "employee-card-sudirman";
   break;
  case "SRIWIJAYA":
  case "MANAGEMENT":
  case "KHALID MEDAN":
   cardClass = "employee-card-sriwijaya";
   break;
  case "KHALID JAKARTA":
  case "MAHAKAM":
   cardClass = "employee-card-mahakam";
   break;
  default:
   cardClass = "employee-card-mahakam";
   break;
 }

 const cardClassName = `my-3 p-1 rounded ${cardClass}`;

 return (
  <Card className={cardClassName} style={{ textAlign: "center" }}>
   <Card.Body>
    <Link to={`/outlet/${outlet._id}`} style={{ textDecoration: "none" }}>
     <Row>
      <Col>
       <strong style={{ color: "white" }}>{outlet.name}</strong>
      </Col>
     </Row>
     <Row>
      <Col>
       <strong>{capitalizeEachWord(outlet.city)}</strong>
      </Col>
     </Row>
     <Row>
      <Col>{outlet.outletCode}</Col>
     </Row>
     <Row>
      <Col>{capitalizeEachWord(outlet.totalEmployees)}</Col>
     </Row>
     <Row>
      <Col>{outlet.dateFound && getWorkDuration(outlet.dateFound, null)}</Col>
     </Row>
    </Link>
   </Card.Body>
  </Card>
 );
};

export default Outlet;
