import React from "react";
import { useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { Link } from "react-router-dom";
import { capitalizeEachWord } from "../utils/utils";

const OutletsTableView = ({ keyword, outlets, loading, error }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems;
   if (items.length) {
    sortableItems = [...items];
   } else {
    sortableItems = items;
   }

   if (sortConfig !== null) {
    if (items.length) {
     sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
     });
    }
   }
   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <table striped bordered hover responsive>
    <thead style={{ position: "sticky", top: 0 }}>
     <tr>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("outletID")}
        className={getClassNamesFor("outletID")}
       >
        Outlet ID
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name")}
       >
        Name
       </button>
      </th>
      <th style={{ textAlign: "left" }}>
       <button
        type="button"
        onClick={() => requestSort("type")}
        className={getClassNamesFor("type")}
       >
        Type
       </button>
      </th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin &&
      Array.isArray(items) &&
      items.map(
       (item, index) =>
        item && (
         <tr key={item._id}>
          <td>
           <Link to={`/outlet/${item._id}`} className="link-black">
            {item.name}
           </Link>
          </td>
          <td>{item.outletID}</td>
          <td>{item.type ? capitalizeEachWord(item.type) : ""}</td>
         </tr>
        )
      )}
    </tbody>
   </table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={outlets} />
   )}
  </>
 );
};

export default OutletsTableView;
