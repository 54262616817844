import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { NavLink, Modal, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
 capitalizeEachWord,
 convertSeconds,
 getWorkDuration,
 calculateLate,
} from "../utils/utils";
import { TooltipButton } from "../components/tooltip";
import { options } from "../utils/utils.jsx";
import { useDispatch, useSelector } from "react-redux";
import { addAttendance, deleteAttendance } from "../actions/attendanceActions";
import { getAnalysisAttendanceSummaryByDate } from "../actions/attendanceSummaryActions.jsx";
import verifiedIcon from "../Assets/icon/verified.png";

const AddAttendanceTableView = ({
 keyword,
 isActiveEmployee,
 outlet,
 date,
 details,
 userInfo,
}) => {
 // State untuk mengontrol visibilitas popup
 const [showAddAttendance, setShowAddAttendance] = useState(false);
 const [addAttendanceItem, setAddAttendanceItem] = useState({});
 const [addAttendanceDate, setAddAttendanceDate] = useState(null);
 const [addAttendanceStatus, setAddAttendanceStatus] = useState("select...");
 const [addAttendanceClockIn, setAddAttendanceClockIn] = useState(null);
 const [addAttendanceClockOut, setAddAttendanceClockOut] = useState(null);
 const [addAttendanceOvertimeHours, setAddAttendanceOvertimeHours] =
  useState(null);
 const [addAttendanceOvertimeType, setAddAttendanceOvertimeType] =
  useState("normal");
 const [addAttendanceData, setAddAttendanceData] = useState(null);
 const [addAttendanceId, setAddAttendanceId] = useState(null);

 const dispatch = useDispatch();
 const attendanceSummaryAnalysis = useSelector(
  (state) => state.attendanceSummaryAnalysis
 );
 const { attendanceSummary } = attendanceSummaryAnalysis;
 // Function to find matching attendance data by date
 const matchingDates = () => {
  if (!addAttendanceDate || !Array.isArray(addAttendanceItem.attendanceData)) {
   setAddAttendanceData(null);
   return;
  }
  const targetDate = dayjs(addAttendanceDate);

  const attendanceData = addAttendanceItem.attendanceData.find(
   (item) =>
    dayjs(item.date).format("YYYY-MM-DD") === targetDate.format("YYYY-MM-DD")
  );
  setAddAttendanceData(attendanceData || null);
 };

 useEffect(
  () => {
   if (addAttendanceDate && showAddAttendance) {
    matchingDates();
    if (addAttendanceData) {
     if (addAttendanceData.clockIn && addAttendanceData.clockOut) {
      setAddAttendanceClockIn(dayjs(addAttendanceData.clockIn).format("HH:mm"));
      setAddAttendanceClockOut(
       dayjs(addAttendanceData.clockOut).format("HH:mm")
      );
      setAddAttendanceOvertimeHours(addAttendanceData.overtimeHours);
      setAddAttendanceOvertimeType(addAttendanceData.overtimeType);
     } else {
      setAddAttendanceClockIn("--:--");
      setAddAttendanceClockOut("--:--");
     }
     setAddAttendanceStatus(addAttendanceData.status);
     setAddAttendanceId(addAttendanceData._id);
    } else {
     // Reset all fields if no data is found
     setAddAttendanceClockIn("--:--");
     setAddAttendanceClockOut("--:--");
     setAddAttendanceOvertimeHours(null);
     setAddAttendanceOvertimeType("normal");
     setAddAttendanceStatus("select...");
     setAddAttendanceId(null);
    }
   }
  },
  // eslint-disable-next-line
  [addAttendanceDate, showAddAttendance, addAttendanceData, attendanceSummary]
 );

 // Cegah refresh saat submit form
 const handleSubmit = (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }
  console.log({ addAttendanceData });
  // Implementasi logika submit di sini tanpa refresh halaman
  //console.log({
  // addAttendanceItem,
  // addAttendanceDate,
  // addAttendanceStatus,
  // addAttendanceClockIn,
  // addAttendanceClockOut,
  //});

  dispatch(
   addAttendance({
    _id: addAttendanceItem._id,
    employeeID: addAttendanceItem.employeeID,
    date: addAttendanceDate,
    clockIn: addAttendanceClockIn,
    clockOut: addAttendanceClockOut,
    status: addAttendanceStatus,
    overtimeHours: addAttendanceOvertimeHours,
    overtimeType: addAttendanceOvertimeType,
    attendanceId: addAttendanceId,
   })
  );

  // Close modal setelah submit
  handleCloseAddAttendance();
  dispatch(
   getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
  );
 };

 const deleteAttendanceHandler = () => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteAttendance(addAttendanceId));
   //_id, attendanceId, employeeID, date
   // Close modal setelah delete
   handleCloseAddAttendance();
   dispatch(
    getAnalysisAttendanceSummaryByDate(isActiveEmployee, keyword, outlet, date)
   );
  }
 };

 // Fungsi untuk membuka dan menutup popup
 const handleCloseAddAttendance = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowAddAttendance(false);
  setAddAttendanceItem({});
  setAddAttendanceDate(null);
  setAddAttendanceStatus(null);
  setAddAttendanceClockIn(null);
  setAddAttendanceClockOut(null);
  setAddAttendanceData(null);
 };
 const handleShowAddAttendance = (event, item) => {
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowAddAttendance(true);
  setAddAttendanceItem(item);
 };
 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems = items?.length ? [...items] : items;

   if (sortConfig !== null && items?.length) {
    sortableItems.sort((a, b) => {
     if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
     }
     if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
     }
     return 0;
    });
   }

   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };
 const dateForPicker = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
 };

 // Fungsi untuk mengubah string tanggal menjadi objek Date
 const dateFromDateString = (dateString) => {
  return dateString ? dayjs(dateString).toDate() : null;
 };

 const lateInText = (employee, clockIn) => {
  if (!employee && !clockIn) {
   return "";
  }

  const outlet = employee.outlet;
  const position = employee.position;

  if (!outlet && !position) {
   return "";
  }
  if (
   typeof outlet === "string" &&
   !outlet.toLowerCase().includes("management") &&
   typeof position === "string" &&
   !position.toLowerCase().includes("security")
  ) {
   if (calculateLate(employee, clockIn, 0)) {
    return `Late ${calculateLate(employee, clockIn, 0)}`;
   }
  }
  return "";
 };

 const lateInNumber = (employee, clockIn) => {
  if (!employee && !clockIn) {
   return 0;
  }

  const outlet = employee.outlet;
  const position = employee.position;

  if (!outlet && !position) {
   return 0;
  }

  if (calculateLate(employee, clockIn, 0)) {
   return calculateLate(employee, clockIn, 0);
  }

  return 0;
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // Membuat array tanggal dari 26 Juli 2024 sampai 25 Agustus 2024
  const startDate = dayjs(date);
  const endDate = dayjs(startDate).add(1, "month").subtract(1, "day");
  const totalDays = endDate.diff(startDate, "day") + 1;
  const dateRange = [];

  //console.log(`startDate: ${startDate.format("DD/MM/YYYY")}`);
  //console.log(`endDate: ${endDate.format("DD/MM/YYYY")}`);

  for (
   let date = startDate;
   date.isBefore(endDate) || date.isSame(endDate);
   date = date.add(1, "day")
  ) {
   dateRange.push(date);
  }

  return (
   <>
    <table striped bordered hover responsive>
     <thead
      style={{ position: "sticky", top: 0, border: "1px solid lightgray" }}
     >
      <tr>
       <th
        style={{
         textAlign: "center",
         padding: "0.5px",
         minWidth: "330px",
         border: "1px solid lightgray",
        }}
       >
        <button
         type="button"
         onClick={() => requestSort("name")}
         className={getClassNamesFor("name")}
        >
         NAME
        </button>
       </th>
       {/* Generate columns for dates */}
       {dateRange.map((date) => (
        <th
         key={date.format("YYYY-MM-DD")}
         style={{
          textAlign: "center",
          border: "1px solid lightgray",
         }}
        >
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {date.format("MMM")}
         </span>
         <br />
         {date.format("DD")}
         <br />
         <span style={{ fontSize: "8px", fontWeight: "normal" }}>
          {capitalizeEachWord(date.format("ddd"))}
         </span>
        </th>
       ))}
      </tr>
     </thead>
     <tbody style={{ color: "#000000" }}>
      {items &&
       items
        .filter((item) => {
         if (isActiveEmployee) {
          return !item.dateResign; // Show only employees who have not resigned
         } else {
          return item.dateResign; // Show only employees who have resigned
         }
        })
        .map((item) => (
         <tr
          key={item._id}
          style={{
           border: "1px solid lightgray",
           maxHeight: "10px", // Set maximum height for the row
           overflow: "hidden", // Hide overflow content
           verticalAlign: "middle", // Vertically align the content
          }}
         >
          <td
           style={{
            minWidth: "200px",
            overflowWrap: "break-word",
            lineHeight: "10px", // Adjust line height to fit the 20px height
            height: "10px", // Set fixed height for the cells
            padding: "0px", // Remove padding to fit the max height
            verticalAlign: "middle", // Vertically align the content
           }}
          >
           <LinkContainer to={`/employee/${item._id}`}>
            <NavLink className="mt-1">
             <div
              style={{
               display: "flex",
               justifyContent: "space-between",
               gap: "10px", // optional, to add space between the image and the name
               textAlign: "center", // Horizontal centering
               verticalAlign: "middle", // Vertical centering
               marginLeft: "5px",
              }}
             >
              <img
               src={
                item.profilePicture
                 ? `../${item.profilePicture}`
                 : `../uploads/images/profilePictures/blank-profile-picture.png`
               }
               alt="Profile"
               style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
               }}
              />
              <span
               style={{
                flexGrow: 1,
                textAlign: "left",
                fontSize: "14px",
                verticalAlign: "middle", // Vertical centering
                fontWeight: "400",
               }}
              >
               {`${capitalizeEachWord(item.name)} `}
               {typeof item.status === "string" &&
                item.status.toLowerCase().includes("tetap") && (
                 <img
                  alt="Verified"
                  src={verifiedIcon}
                  style={{ width: "18px", height: "18px" }}
                 />
                )}
              </span>
              {item.totalSakit > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Sakit:${item.totalSakit}`}
               </button>
              )}
              {item.totalIzin > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Izin:${item.totalIzin}`}
               </button>
              )}

              {item.totalAbsen > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Absen:${item.totalAbsen}`}
               </button>
              )}
              {item.totalSakitNonSurat > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`SNS:${item.totalSakitNonSurat}`}
               </button>
              )}
              {item.totalCutiTahunan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti:${item.totalCutiTahunan}`}
               </button>
              )}
              {item.totalCutiMelahirkan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Melahirkan:${item.totalCutiMelahirkan}`}
               </button>
              )}
              {item.totalCutiIstriMelahirkan > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Istri Melahirkan:${item.totalCutiIstriMelahirkan}`}
               </button>
              )}
              {item.totalCutiAnggotaKeluargaMeninggal > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 fontSize: "10px",
                 color: "black",
                }}
               >
                {`Cuti Keluarga Meninggal:${item.totalCutiAnggotaKeluargaMeninggal}`}
               </button>
              )}
              <button style={{ borderRadius: "5px" }}>
               <span style={{ fontWeight: "400", fontSize: "10px" }}>
                {item.totalPresent}
               </span>
               <span style={{ fontSize: "8px" }}>{`/${totalDays}`}</span>
              </button>
              {userInfo.isAdmin && (
               <button
                onClick={(event) => handleShowAddAttendance(event, item)}
                className="button"
                style={{
                 padding: "5px",
                }}
               >
                <i class="fas fa-edit" style={{ fontSize: "12px" }}></i>
               </button>
              )}
             </div>
            </NavLink>
           </LinkContainer>
           <Row
            style={{
             marginLeft: "27px",
             display: "flex",
             justifyContent: "flex-start",
            }}
           >
            <Col>
             {details && (
              <>
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {item.position &&
                 typeof item.position === "string" &&
                 item.position.length < 4
                  ? item.position
                  : capitalizeEachWord(item.position)}
                </span>
               </button>
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {capitalizeEachWord(item.employeeID)}
                </span>
               </button>
              </>
             )}
             {typeof item.status === "string" &&
              !item.status.toLowerCase().includes("tetap") && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 backgroundColor:
                  typeof item.status === "string" &&
                  item.status.toLowerCase().includes("tetap")
                   ? "rgb(169, 199, 169)"
                   : "rgb(168, 240, 245)",
                 color:
                  typeof item.status === "string" &&
                  item.status.toLowerCase().includes("tetap")
                   ? "rgb(6, 106, 6)"
                   : "rgb(8, 48, 181)",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {capitalizeEachWord(item.status)}
                </span>
               </button>
              )}
            </Col>
            {details && item.dateIn && (
             <div>
              <button
               style={{
                verticalAlign: "middle", // Vertical centering
                borderRadius: "5px",
                backgroundColor: "rgb(169, 199, 169)",
                color: "rgba(52, 58, 64, 0.8)",
                fontWeight: "500",
               }}
              >
               <span
                style={{
                 textAlign: "left",
                 fontSize: "8px",
                 verticalAlign: "middle", // Vertical centering
                }}
               >
                {`Join: ${capitalizeEachWord(
                 dayjs(item.dateIn).format("DD MMM YYYY")
                )}`}
               </span>
              </button>

              {item.dateResign && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 backgroundColor: "rgb(242, 153, 153)",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {`Resign: ${capitalizeEachWord(
                  dayjs(item.dateResign).format("DD-MMM-YYYY")
                 )}`}
                </span>
               </button>
              )}
              <button
               style={{
                verticalAlign: "middle", // Vertical centering
                borderRadius: "5px",
               }}
              >
               <span
                style={{
                 textAlign: "left",
                 fontSize: "8px",
                 verticalAlign: "middle", // Vertical centering
                }}
               >
                {`${capitalizeEachWord(
                 getWorkDuration(dayjs(item.dateIn), dayjs(item.dateResign))
                )}`}
               </span>
              </button>
              {item.totalOvertimeHours > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 backgroundColor: "rgba(204, 153, 255, 1)",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {`Overtime Hours: ${item.totalOvertimeHours}`}
                </span>
               </button>
              )}
              {item.totalOvertimeHLN > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 backgroundColor: "rgb(242, 153, 153)",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {`HLN: ${item.totalOvertimeHLN}`}
                </span>
               </button>
              )}
              {item.totalOvertimeLongshift > 0 && (
               <button
                style={{
                 verticalAlign: "middle", // Vertical centering
                 borderRadius: "5px",
                 backgroundColor: "rgb(242, 153, 153)",
                }}
               >
                <span
                 style={{
                  textAlign: "left",
                  fontSize: "8px",
                  verticalAlign: "middle", // Vertical centering
                 }}
                >
                 {`Longshift: ${item.totalOvertimeLongshift}`}
                </span>
               </button>
              )}
             </div>
            )}
           </Row>
          </td>
          {/* Generate columns for each date */}
          {dateRange.map((date) => {
           const dateString = date.format("YYYY-MM-DD");
           const attendance = item.attendanceData.find((entry) =>
            dayjs(entry.date).isSame(date, "day")
           );

           return (
            <td
             key={`${item._id}-${dateString}`}
             style={{
              border: "1px solid lightgray",
              maxWidth: "80px",
              textAlign: "center", // Horizontal centering
              verticalAlign: "middle", // Vertical centering
              justifyContent: "center",
              fontSize: "8px",
             }}
            >
             <TooltipButton
              date={date.toDate()}
              dateIn={item.dateIn}
              dateResign={item.dateResign}
              text={
               attendance
                ? details
                  ? attendance.status === "hadir"
                    ? `${attendance.status}\ndate: ${dayjs(
                       attendance.date
                      ).format("DD-MMM-YYYY")}\nin: ${dayjs(
                       attendance.clockIn
                      ).format("HH:mm")}\nout: ${dayjs(
                       attendance.clockOut
                      ).format("HH:mm")}\novertime: ${
                       attendance.overtimeHours
                      } hours\novertime type: ${
                       attendance.overtimeType && attendance.overtimeType
                      }\nduration: ${
                       convertSeconds(attendance.workingDurationInSeconds).hours
                      } hours, ${
                       convertSeconds(attendance.workingDurationInSeconds)
                        .minutes
                      } minutes\n${lateInText(
                       item,
                       attendance.clockIn
                      )} minutes`
                    : `${attendance.status}\ndate: ${dayjs(
                       attendance.date
                      ).format("DD-MMM-YYYY")}`
                  : "H"
                : "-"
              }
              buttonText={
               attendance
                ? details
                  ? attendance.status === "hadir"
                    ? attendance.overtimeHours
                      ? `${dayjs(attendance.clockIn).format("HH:mm")}\n${dayjs(
                         attendance.clockOut
                        ).format("HH:mm")} OT ${
                         attendance.overtimeHours
                        } ${lateInText(item, attendance.clockIn)}`
                      : `${dayjs(attendance.clockIn).format("HH:mm")}\n${dayjs(
                         attendance.clockOut
                        ).format("HH:mm")} ${lateInText(
                         item,
                         attendance.clockIn
                        )} ${
                         attendance.overtimeType
                          ? attendance.overtimeType === "hari libur nasional"
                            ? "HLN"
                            : attendance.overtimeType === "longshift"
                            ? "Long"
                            : ""
                          : ""
                        }`
                    : `${attendance.status}`
                  : "H"
                : "-"
              }
              status={attendance && attendance.status}
              late={
               item &&
               attendance &&
               attendance.clockIn &&
               lateInNumber(item, attendance.clockIn)
              }
             />
            </td>
           );
          })}
         </tr>
        ))}
     </tbody>
    </table>
   </>
  );
 };

 return (
  <>
   {<ProductTable products={attendanceSummary} />}
   {/* Modal untuk menampilkan popup */}
   {userInfo.isAdmin && (
    <Modal
     show={showAddAttendance}
     onHide={(event) => handleCloseAddAttendance(event)}
    >
     <Modal.Header closeButton>
      <Modal.Title>Add Attendance</Modal.Title>
     </Modal.Header>
     <Modal.Body>
      {/* Form di dalam popup */}
      <Form onSubmit={handleSubmit}>
       <Row>
        <Col md="3">
         <Form.Group controlId="employeeID">
          <Form.Label>Employee ID</Form.Label>
          <Form.Control
           type="text"
           placeholder="employeeID"
           value={addAttendanceItem.employeeID}
           disabled
          />
         </Form.Group>
        </Col>
        <Col md="9">
         <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
           type="text"
           placeholder="name"
           value={addAttendanceItem.name}
           disabled
          />
         </Form.Group>
        </Col>
       </Row>

       <Row>
        <Col>
         <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
           type="date"
           value={dateForPicker(addAttendanceDate)}
           onChange={(e) => {
            setAddAttendanceDate(dateFromDateString(e.target.value));
            matchingDates();
           }}
          />
         </Form.Group>
        </Col>
        <Col>
         <Form.Group controlId="status">
          <Form.Label>Status</Form.Label>
          <Form.Control
           as="select"
           value={addAttendanceStatus}
           onChange={(e) => setAddAttendanceStatus(e.target.value)}
           style={{
            color: "white",
            backgroundColor: "#ff0000",
            borderRadius: "8px",
           }}
          >
           {options([
            "select...",
            "hadir",
            "sakit",
            "sakit non surat",
            "izin",
            "absen",
            "cuti tahunan",
            "cuti istri melahirkan",
            "cuti melahirkan",
            "cuti anggota keluarga satu rumah meninggal",
           ])}
          </Form.Control>
         </Form.Group>
        </Col>
       </Row>

       <Row>
        <Col>
         <Form.Group controlId="clockIn">
          <Form.Label>Clock In</Form.Label>
          <Form.Control
           type="time"
           value={addAttendanceClockIn}
           onChange={(e) => setAddAttendanceClockIn(e.target.value)}
          />
         </Form.Group>
        </Col>
        <Col>
         <Form.Group controlId="clockOut">
          <Form.Label>Clock Out</Form.Label>
          <Form.Control
           type="time"
           value={addAttendanceClockOut}
           onChange={(e) => setAddAttendanceClockOut(e.target.value)}
          />
         </Form.Group>
        </Col>
       </Row>

       {addAttendanceStatus === "hadir" &&
        typeof addAttendanceItem.outlet === "string" &&
        !addAttendanceItem.outlet.toLowerCase().includes("management") && (
         <Row>
          <Col>
           <Form.Group controlId="overtimeHours">
            <Form.Label>Overtime Hours</Form.Label>
            <Form.Control
             type="number"
             placeholder="overtime hours"
             value={addAttendanceOvertimeHours}
             onChange={(e) => setAddAttendanceOvertimeHours(e.target.value)}
            />
           </Form.Group>
          </Col>
          <Col>
           <Form.Group controlId="overtimeType">
            <Form.Label>Overtime Type</Form.Label>
            <Form.Control
             as="select"
             value={addAttendanceOvertimeType}
             onChange={(e) => setAddAttendanceOvertimeType(e.target.value)}
             style={{
              color: "white",
              backgroundColor: "#ff0000",
              borderRadius: "8px",
             }}
            >
             {typeof addAttendanceItem.status === "string" &&
             addAttendanceItem.status.toLowerCase().includes("tetap")
              ? options(["normal", "longshift", "hari libur nasional"])
              : options(["normal"])}
            </Form.Control>
           </Form.Group>
          </Col>
         </Row>
        )}
      </Form>
     </Modal.Body>
     <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
      {addAttendanceData && (
       <button
        className="button"
        onClick={deleteAttendanceHandler}
        style={{ marginRight: "auto" }} // Agar tombol trash berada di paling kiri
       >
        <i className="fas fa-trash"></i>
       </button>
      )}

      <Button
       variant="secondary"
       onClick={(event) => handleCloseAddAttendance(event)}
      >
       Cancel
      </Button>
      <Button
       variant="danger"
       type="submit"
       onClick={(event) => handleSubmit(event)}
      >
       Apply
      </Button>
     </Modal.Footer>
    </Modal>
   )}
  </>
 );
};

export default AddAttendanceTableView;
