import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uploadProfilePicture } from "../actions/profilePictureActions";
import { getEmployeeDetails } from "../actions/employeeActions";
import { useParams } from "react-router-dom";
import { Button, Form, Row, Col } from "react-bootstrap";

const UploadProfilePicture = ({ match, history }) => {
 const [selectedFile, setSelectedFile] = useState(null);
 const [localMessage, setLocalMessage] = useState("");
 const [isDisabled, setIsDisabled] = useState(true);

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;
 const profilePictureUpload = useSelector(
  (state) => state.profilePictureUpload
 );
 const { loading, message, error } = profilePictureUpload;
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { employee } = employeeDetails;
 const dispatch = useDispatch();
 const { id } = useParams(); // Get the id from the URL params

 useEffect(() => {
  if (!loading && !userInfo) {
   history.push("/login");
  } else if (!employee || id !== employee._id) {
   dispatch(getEmployeeDetails(id));
  }
 }, [dispatch, userInfo, loading, employee, id, history, isDisabled]);

 const handleFileChange = (e) => {
  const file = e.target.files[0];
  const validTypes = ["image/png", "image/jpeg", "image/jpg"];
  const maxSize = 500 * 1024; // 500 KB

  if (file && validTypes.includes(file.type) && file.size <= maxSize) {
   setSelectedFile(file);
   setLocalMessage(""); // Clear any previous messages
  } else {
   setLocalMessage(
    "Please upload a valid image (.png, .jpg, .jpeg) under 500 KB."
   );
   setSelectedFile(null);
  }
 };

 const handleUpload = async () => {
  if (!selectedFile) {
   setLocalMessage("Please select a file first.");
   return;
  }

  try {
   if (employee && employee._id && selectedFile) {
    await dispatch(uploadProfilePicture(employee, selectedFile));
    setIsDisabled(true);
    dispatch(getEmployeeDetails(id));
   }
  } catch (error) {
   setLocalMessage(`Error: ${error}`);
  }
 };

 return (
  <div style={{ textAlign: "center" }}>
   {message && <p>{message}</p>}
   {localMessage && <p>{localMessage}</p>}
   {error && <p>{error}</p>}
   {
    <div>
     <img
      src={
       employee && employee.profilePicture
        ? `../${employee.profilePicture}`
        : employee &&
          !employee.profilePicture &&
          `../uploads/images/profilePictures/blank-profile-picture.png`
      }
      alt="Profile"
      style={{
       width: "200px",
       height: "200px",
       borderRadius: "50%",
      }}
     />
     {isDisabled ? (
      <Button
       variant="danger"
       onClick={() => setIsDisabled(false)}
       style={{ verticalAlign: "bottom" }}
      >
       <i className="fas fa-camera" style={{ fontSize: "1.75em" }} />
      </Button>
     ) : (
      <Button
       variant="secondary"
       onClick={() => setIsDisabled(true)}
       style={{ verticalAlign: "bottom" }}
      >
       Cancel
      </Button>
     )}
     {!isDisabled && (
      <Row className="justify-content-center">
       <Col md="auto">
        <h5>Upload Profile Picture</h5>

        <Form.Group controlId="formFile" className="mt-3 mb-3" md="6">
         <Form.Label className="text-danger">
          maximum size 500 KB, only accept .jpg, .jpeg, .png
         </Form.Label>
         <Form.Control
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={handleFileChange}
         />
        </Form.Group>
        <Button variant="danger" onClick={handleUpload} disabled={loading}>
         Upload
        </Button>
       </Col>
      </Row>
     )}
    </div>
   }
  </div>
 );
};

export default UploadProfilePicture;
