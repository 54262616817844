import React from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {
 capitalizeEachWord,
 getWorkDuration,
 calculateLate,
 attendanceText,
} from "../utils/utils";
import iconExcelDownload from "../Assets/icon/icon_excel_download.png";

const AttendanceExportToExcel = ({
 attendanceData,
 startDate,
 outlet,
 isActiveEmployee,
}) => {
 // Fungsi untuk mengekspor data ke Excel
 const exportToExcel = () => {
  if (!Array.isArray(attendanceData)) {
   alert("Tidak ada data untuk diekspor!");
   return;
  }

  const initialDate = dayjs(startDate);
  const endDate = dayjs(initialDate).add(1, "month").subtract(1, "day");
  const totalDays = endDate.diff(initialDate, "day") + 1;

  const dateRange = [];
  let currentDate = initialDate;
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
   dateRange.push(currentDate.format("DD-MM-YYYY"));
   currentDate = currentDate.add(1, "day");
  }

  const formattedData = [];
  const headerRow = [
   "Employee ID",
   "Name",
   "Position",
   "Status",
   "Date In",
   "Date Resign",
   "Work Duration",
   "Total Days",
   "Hadir",
   "Total Overtime Hours",
   "Total Longshift (Hari)",
   "Total HLN (Hari)",
   "Sakit",
   "Sakit non surat",
   "Izin",
   "Absen",
   "Cuti tahunan",
   "Cuti istri melahirkan",
   "Cuti melahirkan",
   "Cuti anggota keluarga satu rumah meninggal",
  ];

  dateRange.forEach((date) => {
   headerRow.push(date);
  });

  // Tambahkan header untuk totalLateInMinutes
  headerRow.push("Total Late In Minutes > 5 Minutes (Hari)");
  headerRow.push("Total Late In Minutes > 0 Minutes (Hari)");

  formattedData.push(headerRow);

  attendanceData.forEach((employee) => {
   let totalLateInMinutesMoreThan5 = 0;
   let totalLateInMinutesMoreThan0 = 0;

   const outlet = employee.outlet;
   const position = employee.position;
   // Kalkulasi totalLateInMinutes jika outlet dan position memenuhi syarat
   if (typeof outlet === "string" && typeof position === "string") {
    employee.attendanceData.forEach((attendance) => {
     if (attendance.clockIn) {
      const totalLate6 = calculateLate(employee, attendance.clockIn);
      const totalLate = calculateLate(employee, attendance.clockIn, 0);

      totalLateInMinutesMoreThan5 += totalLate6 ? 1 : 0;
      totalLateInMinutesMoreThan0 += totalLate ? 1 : 0;
     }
    });
   }

   const employeeRow = [
    employee.employeeID,
    employee.name,
    employee.position,
    employee.status,
    employee.dateIn ? dayjs(employee.dateIn).format("DD-MM-YYYY") : "-",
    employee.dateResign ? dayjs(employee.dateResign).format("DD-MM-YYYY") : "-",
    getWorkDuration(employee.dateIn, employee.dateResign),
    totalDays,
    employee.totalHadir,
    employee.totalOvertimeHours,
    employee.totalOvertimeLongshift,
    employee.totalOvertimeHLN,
    employee.totalSakit,
    employee.totalSakitNonSurat,
    employee.totalIzin,
    employee.totalAbsen,
    employee.totalCutiTahunan,
    employee.totalCutiIstriMelahirkan,
    employee.totalCutiMelahirkan,
    employee.totalCutiAnggotaKeluargaMeninggal,
   ];

   dateRange.forEach((date) => {
    const attendance = employee.attendanceData.find(
     (attendance) => dayjs(attendance.date).format("DD-MM-YYYY") === date
    );

    if (attendance) {
     employeeRow.push(attendanceText(employee, attendance));
    } else {
     employeeRow.push("x");
    }
   });

   // Tambahkan totalLateInMinutesMoreThan5 di akhir setiap row
   employeeRow.push(totalLateInMinutesMoreThan5);
   employeeRow.push(totalLateInMinutesMoreThan0);

   formattedData.push(employeeRow);
  });

  const totalEmployee = attendanceData.length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(formattedData);
  XLSX.utils.book_append_sheet(
   workbook,
   worksheet,
   outlet ? `${capitalizeEachWord(outlet)}_${totalEmployee}` : "Attendance Data"
  );

  XLSX.writeFile(
   workbook,
   outlet
    ? `Attendance_${capitalizeEachWord(outlet)}_${initialDate.format(
       "DDMMYYYY"
      )}-${endDate.format("DDMMYYYY")}.xlsx`
    : "AttendanceData.xlsx"
  );
 };

 return (
  <div>
   <button className="button" onClick={exportToExcel}>
    <img
     src={iconExcelDownload}
     alt="Excel"
     style={{
      width: "24px",
      height: "28px",
      paddingRight: "2px",
     }}
    />
    Download
   </button>
  </div>
 );
};

export default AttendanceExportToExcel;
