/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listOutlets, addOutlet, deleteOutlet } from "../actions/outletActions";
import {
 Row,
 Col,
 Container,
 Form,
 Modal,
 Button,
 ToggleButtonGroup,
 ToggleButton,
} from "react-bootstrap";
import {
 options,
 capitalizeEachWord,
 countActiveEmployee,
 countStatusEmployee,
 stringIncludes,
} from "../utils/utils.jsx";
import dayjs from "dayjs";
import { logout } from "../actions/userActions";
import Outlet from "../components/outlet.jsx";
import OutletsTableView from "./outletTableView.jsx";

const OutletListView = ({ match, history }) => {
 const [isGrid, setIsGrid] = useState(true);
 const [showAddOutlet, setShowAddOutlet] = useState(false);
 const [addOutletItem, setAddOutletItem] = useState({});

 const [name, setName] = useState("");
 const [outletID, setOutletID] = useState("");
 const [lateInTolerance, setLateInTolerance] = useState("");
 const [type, setType] = useState("");
 const [outletId, setOutletId] = useState("");

 const defaultOptions = "Select...";
 const keyword = match.params.keyword;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const dispatch = useDispatch();

 const outletsList = useSelector((state) => state.outletsList);
 const { loading, error, outlets } = outletsList;

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  dispatch(listOutlets(keyword));
 }, [keyword, userLoading, userInfo, outlets, error, history]);

 const handleCloseAddOutlet = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowAddOutlet(false);
  setAddOutletItem({});
  setName(null);
  setOutletID(null);
  setLateInTolerance(null);
  setType(null);
  setOutletId(null);
 };

 const handleShowAddOutlet = (event, items) => {
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowAddOutlet(true);
  setAddOutletItem(items);
 };

 const handleSubmit = (event) => {
  if (event) {
   event.preventDefault(); // Mencegah halaman refresh
  }

  dispatch(
   addOutlet({
    name: name,
    outletID: outletID,
    type: type,
    lateInTolerance: lateInTolerance,
   })
  );
 };

 const deleteOutletHandler = () => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteOutlet(outletId));

   // Close modal setelah delete
   handleCloseAddOutlet();
   dispatch(dispatch(listOutlets(keyword)));
  }
 };

 return (
  <>
   <>
    <Row style={{ justifyContent: "center" }}>
     <Col md="auto">
      <h3>Employees</h3>
     </Col>
    </Row>

    <Row className="justify-content-md-center">
     <Col md="auto">
      <ToggleButtonGroup type="radio" name="options" defaultValue={"Grid"}>
       <ToggleButton
        key="Grid"
        id="tbg-radio-1"
        value="Grid"
        name="radio"
        onChange={(e) => setIsGrid(e.target.value === "Grid")}
        style={{
         backgroundColor: isGrid ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
       >
        Grid
       </ToggleButton>
       <ToggleButton
        key="Table"
        id="tbg-radio-2"
        value="Table"
        name="radio"
        onChange={(e) => setIsGrid(e.target.value === "Grid")}
        style={{
         backgroundColor: !isGrid ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
       >
        Table
       </ToggleButton>
      </ToggleButtonGroup>
     </Col>
    </Row>
    <Container>
     <Row style={{ justifyContent: "center" }}>
      <Col md="auto">
       <h3>Outlets</h3>
      </Col>
     </Row>
    </Container>
   </>
   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     <Row>
      <Col>
       <Button variant="danger" onClick={() => handleShowAddOutlet()}>
        Add Outlet
       </Button>
      </Col>
     </Row>
     <Row>
      {Array.isArray(outlets) && outlets.length ? (
       isGrid ? (
        outlets.map((outlet) => (
         <Col key={outlet._id} sm={12} md={6} lg={4} xl={3}>
          <Outlet outletDetails={outlet} />
         </Col>
        ))
       ) : (
        <OutletsTableView
         keyword={keyword}
         outlets={outlets}
         loading={loading}
         error={error}
        />
       )
      ) : null}
     </Row>
     <Modal
      show={showAddOutlet}
      onHide={(event) => handleCloseAddOutlet(event)}
     >
      <Modal.Header closeButton>
       <Modal.Title>Add Outlet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {/* Form di dalam popup */}
       <Form onSubmit={handleSubmit}>
        <Row>
         <Col md="3">
          <Form.Group controlId="outletID">
           <Form.Label>Outlet ID</Form.Label>
           <Form.Control
            type="number"
            placeholder="employeeID"
            value={outletID}
            onChange={(e) => setOutletID(e.target.value)}
            onWheel={(e) => e.target.blur()}
           />
          </Form.Group>
         </Col>
         <Col md="9">
          <Form.Group controlId="name">
           <Form.Label>Name</Form.Label>
           <Form.Control
            type="text"
            placeholder="name"
            value={name}
            onChange={(e) => setAddOutletItem(e.target.value)}
           />
          </Form.Group>
         </Col>
        </Row>

        <Row>
         <Col>
          <Form.Group controlId="type">
           <Form.Label>
            Type<span>*</span>
           </Form.Label>
           <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="">{defaultOptions}</option>
            <option value="outlet">Outlet</option>
            <option value="khalid">Central Kitchen / Khalid</option>
           </Form.Select>
          </Form.Group>
         </Col>
        </Row>
       </Form>
      </Modal.Body>
      <Modal.Footer
       style={{ display: "flex", justifyContent: "space-between" }}
      >
       {/*
        addAttendanceData && (
         <button
          className="button"
          onClick={deleteAttendanceHandler}
          style={{ marginRight: "auto" }} // Agar tombol trash berada di paling kiri
         >
          <i className="fas fa-trash"></i>
         </button>
        )
        */}

       <Button
        variant="secondary"
        onClick={(event) => handleCloseAddOutlet(event)}
       >
        Cancel
       </Button>
       <Button
        variant="danger"
        type="submit"
        onClick={(event) => handleSubmit(event)}
       >
        Apply
       </Button>
      </Modal.Footer>
     </Modal>
    </>
   )}
  </>
 );
};

export default OutletListView;
